import React, {CSSProperties, ReactNode} from 'react';
import './App.css';

import { Container } from "@mui/material";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";

import { Youtube, H3, H2, Caption, VSpacer, useWindowSize, useIsSp } from "./Utils"
import { isJSDocNullableType } from 'typescript';

function MainTitle() {
  const isSp = useIsSp()

  const style: CSSProperties = {
    fontFamily: "TitleFont",
    fontWeight: 900,
    fontSize: isSp ? 42 : 80,
    color: "#e60012",
    lineHeight: "1em",
  }

  return <Container style={style}>
    <div>TOKYO 2020</div>
    <div>CLOSE <span style={{whiteSpace: "nowrap"}}>THE DISTANCE</span></div>

    <div style={{fontSize: isSp ? 16 : 40}}>-Real-time remote marathon cheering-</div>
  </Container>
}

function MainVideo() {
  return <Container>
    <Youtube id={"pLojieiKPVY"}></Youtube>
  </Container>
}


function Title(props: {children: ReactNode}) {
  return <div style={{
    fontFamily: "TitleFont",
    fontWeight: 900,
    fontSize: 40,
    color: "#e60012",
  }}>{props.children}</div>
}

function Text(props: {children: ReactNode}) {
  return <div style={{
    // fontFamily: "TitleFont",
    fontSize: 16,
    color: "white",
    lineHeight: "1.4em",
  }}>{props.children}</div>
}

function Problem() {
  return <Container>
    <Title>PROBLEM</Title>
    <VSpacer height={8}/>
    <Text>
      Due to the pandemic, the Tokyo 2020 Olympic Marathon was to be a spectator-free event. The situation complicated direct transmission of the fans’ cheers to the athletes. Surely there was a way to convey wayside encouragement to marathon runners. NTT embarked upon an innovative experiment to turn remote cheers into firsthand support. 
    </Text>
  </Container>;
}

function Challenge() {
  return <Container>
    <Title>CHALLENGE</Title>
    <VSpacer height={8}/>
    <Text>
      Runners dash by at a speed of 5 meters per second. Even a 1-second time lag would leave Tokyo cheers meaningless. This major issue of time lag was resolved with NTT’s Ultra-low-latency Communications Technology. Implementation of this technology become the key to connecting marathon runners in Sapporo and cheering supporters in Tokyo. NTT’s Ultra-low-latency Communications Technology allowed large-capacity video shot by 8 cameras to be transmitted to the 50-meter LED monitor without compressing the video, thus eliminating processing delays. This enabled real-time viewing of the marathon from Tokyo and instantaneous delivery of spectator cheers to the athletes.
    </Text>
  </Container>;
}

function Result() {
  return <Container>
    <Title>RESULT</Title>
    <VSpacer height={8}/>
    <Text>
      Cheers were conveyed to Sapporo in real time with no transmission-related processing delays. Cries of delight from athletes and spectators alike were captured on multiple TV broadcasts. The power of technology overcame restrictive circumstances, instantaneously uniting people’s thoughts and feelings.
    </Text>
  </Container>;
}

function Technology() {
  return <Container>
    <Title>TECHNOLOGY</Title>
    <VSpacer height={8}/>
    <Text>
      Efficient transmission of large-capacity video normally requires the video to be compressed. Yet the compression processing itself is a major cause of time lag. NTT has developed network equipment that can transmit large-capacity uncompressed video without packet loss. This development successfully reduced one-way transmission delay between Tokyo and Sapporo from 1 second to 20 milliseconds.
    </Text>

    <VSpacer height={32} />

    <img style={{width: "100%", border: "1px solid white"}} src="images/technology-1@8x-50.jpg"></img>
    <VSpacer height={8} />

    <div style={{display: "flex", alignContent: "center", justifyContent: "center"}}>
      <img style={{width: "calc(50% - 8px)", border: "1px solid white"}} src="images/technology-2@8x-50.jpg"></img>
      <div style={{flexGrow: 1}}></div>
      <img style={{width: "calc(50% - 8px)", border: "1px solid white"}} src="images/technology-3@8x-50.jpg"></img>
    </div>


  </Container>;
}

function Photos() {
  const photos = [
    "photo_01@8x-50.jpg",
    "photo_02@8x-50.jpg",
    "photo_03@8x-50.jpg",
    "photo_04@8x-50.jpg",
    "photo_05@8x-50.jpg",
    "photo_06@8x-50.jpg",
  ]

  return <Container>
    <div style={{fontFamily: "TitleFont", fontSize: 24, marginBottom: 8}}>TOKYO</div>
    <img style={{width: "100%", marginBottom: 4}} src="images/Tokyo-02@8x-50.jpg"></img>
    <img style={{width: "100%", marginBottom: 4}} src="images/Tokyo-01@8x-50.jpg"></img>

    <VSpacer height={30}></VSpacer>

    <div style={{fontFamily: "TitleFont", fontSize: 24, marginBottom: 8}}>SAPPORO</div>
    <img style={{width: "100%", marginBottom: 4}} src="images/sapporo-02@8x-50.jpg"></img>
    <img style={{width: "50%", marginBottom: 4}} src="images/sapporo-01@8x-50.jpg"></img>
    <img style={{width: "50%", marginBottom: 4}} src="images/sapporo-03@8x-50.jpg"></img>
    <img style={{width: "100%", marginBottom: 4}} src="images/sapporo-04@8x-50.jpg"></img>

    <VSpacer height={30}></VSpacer>

    <hr />
    
    <VSpacer height={30}></VSpacer>

    { photos.map((filename, i) => 
      <img style={{width: "50%", marginBottom: 4}} src={"images/" + filename} key={i}></img>) 
    }


  </Container>
}

function App() {
  const size = useWindowSize()

  const style: CSSProperties = {
    backgroundColor: "#000000",
    minHeight: size.height,
    color: 'white',
    overflow: 'hidden',
  }

  return (
    <div style={style}>
      <VSpacer height={60} />
      <MainTitle></MainTitle>
      <VSpacer height={20} />
      <MainVideo></MainVideo>

      <VSpacer height={20} />

      <Problem />
      <VSpacer height={40} />

      <Challenge />
      <VSpacer height={40} />

      <Result />
      <VSpacer height={40} />

      <Technology />
      <VSpacer height={40} />
      <hr />
      <VSpacer height={40} />

      <Photos />

      <VSpacer height={60} />
    </div>
  );
}

export default App;

import React, { CSSProperties, useState, useEffect } from "react"
import { Box } from "@mui/material";
import { ReactNode } from "react";

export function Youtube({id, options = {}}: {id: string, options?: {[key: string]: any}}) {

  const optionsWithPlaylist: {[key: string]: string} = {
    playlist: id,
    ...options
  }

  const query = Object.keys(optionsWithPlaylist).map(key => [key, String(optionsWithPlaylist[key])].join('=')).join('&')

  const containerStyle: CSSProperties = {
    width: '100%',
    aspectRatio: "16/9",
  }

  const iframeStyle: CSSProperties = {
    width: "100%",
    height: "100%",
  }

  return <div style={containerStyle}>
    <iframe 
      width="560" height="315" 
      src={`https://www.youtube.com/embed/${id}?${query}`} 
      frameBorder={0} 
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
      allowFullScreen={true}
      style={iframeStyle}
      ></iframe>
  </div>
}

export function VSpacer({height}: {height: (number | string)}) {
  return <Box sx={{height: height, width: "100%"}}></Box>
}

export function H2({children}: {children: ReactNode}) {
  const isSp = useIsSp();
  return <h2 style={{fontSize: 28, textAlign: isSp ? "center" : "left"}}>
    <span style={{borderBottom: "3px solid white", paddingBottom: 8}}>
    {children}
    </span> </h2>
}

export function H3({children}: {children: ReactNode}) {
  const isSp = useIsSp();
  return <h3 style={{fontSize: 20, textAlign: isSp ? "center" : "left"}}>
    <span style={{color: "black", backgroundColor: "white", padding: 8}}>
      {children}
    </span></h3>
}

export function Caption({children}: {children: ReactNode}) {
  return <p style={{
    textAlign: "center",
    fontWeight: 'bold',
    margin: 0,
    marginTop: 8}}>
    {children}
  </p>
}

export function useWindowSize() {
  function getCurrentSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  }

  const [size, setSize] = useState(getCurrentSize());

  useEffect(() => {
    function onResize() {
      setSize(getCurrentSize())
    }

    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    }
  }, [])

  return size;
}

export function useIsSp() {
  const {width, height} = useWindowSize();
  return width <= 600
}


